@import 'styles/includes.scss';

.HeroImageText {
    position: relative;
    @extend %container-large;

    &__ImageWrapper {
        padding-bottom: 452px;
        width: 100%;
        position: relative;
        margin: 0 auto;

        @include media(m) {
            height: 513px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            background-position-x: center;
            background-position-y: center;
        }
    }

    &__ContentWrapper {
        @extend %container-large;
        position: absolute;
        transform: none;
        display: flex;
        align-items: center;
        justify-content: start;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding-right: 1.6rem;

        @include media(s) {
            padding-right: 0;
        }

        @include media(m) {
            position: absolute;
            transform: none;
            display: flex;
            align-items: center;
            justify-content: start;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    &__Content {
        padding: 3rem;
        background-color: #39668D;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 40rem;

        @include media(s) {
            max-width: 50rem;
        }

        @include media(m) {
            max-width: 60rem;
        }
    }

    &__Title {
        font-family: $fontRaleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38.4px;
        color: white;

        @include media(m) {
            font-size: 48px;
            line-height: 57.6px;
        }
    }

    &__Text {
        font-family: $fontOpenSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.4px;
        color: white;

        @include media(m) {
            font-size: 20px;
            line-height: 28px;
        }
    }
}

@import 'styles/includes.scss';

.HomePage {
    &__container {
        @extend %container;
    }

    &__NoticeWrapper {
        margin-top: 30px;

        @include media(m) {
            margin-top: 60px;
        }
    }
}

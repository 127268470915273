@import 'styles/includes.scss';

.Notice {
    $root: &;

    @extend %container;
    margin-top: 16px;

    @include media(m) {
        margin-top: 32px;
    }

    &__Container {
        background: #fff;
        color: $colorBlack;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.6rem;
        padding: 2rem 2.4rem;
    }

    &__Icon {
        padding-right: 2.4rem;
    }

    &__Link {
        cursor: pointer;
        color: black;
    }

    &__Text {
        @extend %notices;
        white-space: wrap;
        color: $colorBlack;
        line-height: 30px;
        position: relative;
        width: fit-content;

        &::after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 9px;
            background-image: url(/assets/svg/arrow--right-black.svg);
            background-repeat: no-repeat;
            background-position: 100%;
            background-size: contain;
            font-weight: 400;
            vertical-align: middle;
            margin-left: 8px;
        }
    }
}
